import * as React from "react"
import { Link } from "gatsby"
import { Navbar, Container } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

const TheNavbar = () => {
  return (
    <>
      <Navbar expand="lg">
        <Container className="navbar-container d-flex justify-content-start">
          <Link to={"/"}>
            <StaticImage
              className="navbar-image"
              src="../images/LogoSamsonschule.png"
              height="130" style={{ marginLeft: 5 }}
              placeholder="BLURRED"
            />
          </Link>
          <Link to={"https://www.moses-mendelssohn-stiftung.de/"}>
            <StaticImage
              className="navbar-image"
              src="../images/stiftung.png"
              height="130" style={{ marginLeft: 5 }}
              placeholder="BLURRED"
            />
          </Link>
        </Container>
      </Navbar>
    </>
  )
}

export default TheNavbar
